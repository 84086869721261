<template>
  <q-dialog v-if="opened" v-model="opened">
    <q-card style="max-width: 80vw;">
      <q-card-section class="row justify-center">
        <Datatable :data="transactions" :columns="columns" />
      </q-card-section>
      <q-card-actions align="center">
        <q-btn label="Fechar" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import formatString from "@/helpers/formatString";
import Datatable from "@/components/Datatable";

export default {
  components: {
    Datatable
  },
  data() {
    return {
      opened: false,
      transactions: null,
      columns: [
        {
          name: "date",
          field: "date",
          format: val => `${formatString(val, "date")}`,
          label: "Data",
          align: "left",
          sortable: true
        },
        {
          name: "operation",
          field: row => (row.operation === "add" ? "Compra" : "Venda"),
          label: "Operação",
          align: "center",
          sortable: true
        },
        {
          name: "quantity",
          field: "quantity",
          format: val => `${formatString(val, "number")}`,
          label: "Quantidade",
          align: "right",
          sortable: true
        },
        {
          name: "price",
          field: "price",
          format: val => `${formatString(val, "currency")}`,
          label: "Preço",
          align: "right",
          sortable: true
        },
        {
          name: "total",
          field: row => row.price * row.quantity,
          format: val => `${formatString(val, "currency")}`,
          label: "Total",
          align: "right",
          sortable: true
        },
        {
          name: "observation",
          field: "observation",
          label: "Observação",
          align: "left",
          sortable: true
        }
      ]
    };
  },
  methods: {
    formatString,
    open(transactions) {
      if (transactions && transactions.length > 0) {
        this.opened = true;
        this.transactions = transactions;
      } else {
        this.$q.notify({
          color: "orange",
          textColor: "white",
          message: "Não ha transações a serem exibidas",
          icon: "announcement",
          position: "top"
        });
      }
    }
  }
};
</script>

<style></style>
