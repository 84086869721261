<template>
  <div class="q-pa-md">
    <Datatable
      :data="filteredTickers"
      :columns="columns"
      :height="workareaHeight"
      :showVisibleColumns="true"
      :actions="tableActionsRight"
      :enableSearch="true"
      @onFilter="onFilter"
    />
    <TransactionsForm ref="TransactionsForm" @confirm="confirm" />
    <TransactionsList ref="TransactionsList" />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable";
import TransactionsForm from "./TransactionsForm";
import TransactionsList from "./TransactionsList";
import formatString from "@/helpers/formatString";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {
    Datatable,
    TransactionsForm,
    TransactionsList
  },
  data() {
    return {
      filter: null,
      columns: [
        {
          label: "Ações",
          name: "actions",
          align: "center",
          actions: [
            {
              icon: "add",
              color: "positive",
              tooltip: "Adiciona Transação Compra",
              handler: ticker => this.openForm("add", ticker)
            },
            {
              icon: "remove",
              color: "negative",
              tooltip: "Adiciona Transação Venda",
              handler: ticker => this.openForm("remove", ticker)
            },
            {
              icon: "list",
              color: "orange",
              tooltip: "Lista Transações",
              handler: ticker => this.openList(ticker)
            }
          ]
        },

        {
          name: "ticker",
          field: "ticker",
          label: "Ativo",
          align: "left",
          sortable: true
        },
        {
          name: "tickerTypeTitle",
          field: "tickerTypeTitle",
          label: "Tipo Ativo",
          align: "left",
          sortable: true
        },
        {
          name: "quantity",
          field: "quantity",
          label: "Quantidade",
          align: "right",
          format: (val, row) =>
            `${formatString(val, "number", row.decimalPlaces)}`,
          sortable: true
        },
        {
          name: "averagePrice",
          field: "averagePrice",
          label: "Prc. Médio",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "lastPrice",
          field: "lastPrice",
          label: "Prc. Últ. Compra",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "lastPurchase",
          field: "lastPurchase",
          label: "Dt. Últ. Compra",
          align: "left",
          format: val => `${formatString(val, "date")}`,
          sortable: true
        },
        {
          name: "currentQuote",
          field: "currentQuote",
          label: "Cotação Atual",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "currentEquity",
          field: "currentEquity",
          label: "Patrimônio Atual",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "exchangeVariation",
          field: "exchangeVariation",
          label: "Variação Cambial",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "tickerValuation",
          field: "tickerValuation",
          label: "Valorização Ativo",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "profitOrLossValue",
          field: "profitOrLossValue",
          label: "Vlr. Lucro/Prejuízo",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "profitOrLossPercentage",
          field: "profitOrLossPercentage",
          label: "% Lucro/Prejuízo",
          align: "right",
          format: val => `${formatString(val, "number", 2, "%")}`,
          sortable: true
        },
        {
          name: "currentPercentage",
          field: "currentPercentage",
          label: "% Atual",
          align: "right",
          format: val => `${formatString(val, "number", 2, "%")}`,
          sortable: true
        },
        {
          name: "targetPercentage",
          field: "targetPercentage",
          label: "% Objetivo",
          align: "right",
          format: val => `${formatString(val, "number", 2, "%")}`,
          sortable: true
        },
        {
          name: "differencePercentage",
          field: "differencePercentage",
          label: "% Diferença",
          align: "right",
          format: val => `${formatString(val, "number", 2, "%")}`,
          sortable: true
        },
        {
          name: "contributionQuantity",
          field: "contributionQuantity",
          label: "Qtd. Aporte",
          align: "right",
          format: (val, row) =>
            `${formatString(val, "number", row.decimalPlaces)}`,
          sortable: true
        },
        {
          name: "contributionValue",
          field: "contributionValue",
          label: "Vlr. Aporte",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "predictedEquity",
          field: "predictedEquity",
          label: "Patrimônio Previsto",
          align: "right",
          format: val => `${formatString(val, "currency")}`,
          sortable: true
        },
        {
          name: "predictedParticipation",
          field: "predictedParticipation",
          label: "% Previsto",
          align: "right",
          format: val => `${formatString(val, "number", 2, "%")}`,
          sortable: true
        }
      ],
      tableActionsRight: [
        {
          color: "blue",
          label: "Balanceamento da Carteira",
          class: "q-mr-md",
          icon: "monetization_on",
          handle: () => this.getContributionAmount()
        }
      ]
    };
  },
  computed: {
    ...mapGetters("Tickers", ["tickers"]),
    ...mapState("Tickers", ["contributionAmount"]),
    ...mapState("Shared", ["workareaHeight"]),
    filteredTickers() {
      if (!this.filter || this.filter.trim().length <= 0) {
        return this.tickers;
      }
      return this.tickers.filter(ticker =>
        ticker.ticker.toUpperCase().includes(this.filter.trim().toUpperCase())
      );
    }
  },
  methods: {
    formatString,
    ...mapActions("Tickers", ["updateTickerTransactions", "updateQuotes"]),
    ...mapMutations("Tickers", ["setContributionAmount"]),
    openForm(operation, ticker) {
      this.$refs.TransactionsForm.open(operation, ticker);
    },
    async confirm(ticker) {
      this.$q.loading.show({
        message:
          'Adicionando transação.<br/><span class="text-primary">Aguarde...</span>'
      });
      try {
        await this.updateTickerTransactions(ticker);
        this.$q.loading.hide();
      } catch (error) {
        console.error(error.message);
        this.$q.loading.hide();
        this.$q.notify({
          color: "red",
          textColor: "white",
          message: "Falha ao incluir transação",
          icon: "announcement",
          position: "top"
        });
      }
    },
    openList(ticker) {
      this.$refs.TransactionsList.open(ticker.transactions);
    },
    getContributionAmount() {
      this.$q
        .dialog({
          title: "Balanceamento de Carteira",
          message: "Qual será o valor do aporte?",
          prompt: {
            model: this.contributionAmount,
            type: "number",
            prefix: "R$",
            isValid: val => val >= 0
          },
          ok: {
            push: true,
            color: "positive",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Não"
          }
        })
        .onOk(data => {
          this.setContributionAmount(parseFloat(data));
        });
    },
    onFilter(filter) {
      this.filter = filter;
    }
  },
  async created() {
    this.setContributionAmount(0);
    this.$q.loading.show({
      message:
        'Atualizando cotações.<br/><span class="text-primary">Aguarde...</span>'
    });
    try {
      await this.updateQuotes();
      this.$q.loading.hide();
    } catch (error) {
      console.error(error.message);
      this.$q.loading.hide();
    }
  }
};
</script>

<style></style>
