var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.opened)?_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('q-card',[_c('q-card-section',{staticClass:"row items-center"},[_c('span',{staticClass:"text-h6"},[_vm._v("Incluão de transação de "),_c('span',{class:("text-" + _vm.colorTitle)},[_vm._v(_vm._s(_vm.title))]),_vm._v(" do ativo "),_c('b',[_vm._v(_vm._s(_vm.ticker.ticker))])])]),_c('q-card-section',[_vm._v(" "+_vm._s(_vm.showDate)+" "),_c('q-btn',{attrs:{"icon":"event","round":"","color":"primary","size":"xs"}},[_c('q-popup-proxy',{attrs:{"transition-show":"scale","transition-hide":"scale"},on:{"before-show":_vm.updateProxy}},[_c('q-date',{model:{value:(_vm.proxyDate),callback:function ($$v) {_vm.proxyDate=$$v},expression:"proxyDate"}},[_c('div',{staticClass:"row items-center justify-end q-gutter-sm"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Cancel","color":"primary","flat":""}}),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"OK","color":"primary","flat":""},on:{"click":_vm.save}})],1)])],1)],1),_c('q-field',{attrs:{"label":"Quantidade"},scopedSlots:_vm._u([{key:"control",fn:function(ref){
var id = ref.id;
var floatingLabel = ref.floatingLabel;
var value = ref.value;
var emitValue = ref.emitValue;
return [_c('money',_vm._b({directives:[{name:"show",rawName:"v-show",value:(floatingLabel),expression:"floatingLabel"}],staticClass:"q-field__input text-right",attrs:{"id":id,"value":value},on:{"input":emitValue}},'money',_vm.numberFormat,false))]}}],null,false,2599909490),model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}),_c('q-field',{attrs:{"label":"Preço Unitário"},scopedSlots:_vm._u([{key:"control",fn:function(ref){
var id = ref.id;
var floatingLabel = ref.floatingLabel;
var value = ref.value;
var emitValue = ref.emitValue;
return [_c('money',_vm._b({directives:[{name:"show",rawName:"v-show",value:(floatingLabel),expression:"floatingLabel"}],staticClass:"q-field__input text-right",attrs:{"id":id,"value":value},on:{"input":emitValue}},'money',_vm.moneyFormat,false))]}}],null,false,3824299297),model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('q-input',{attrs:{"type":"textarea","label":"Observações"},model:{value:(_vm.form.observation),callback:function ($$v) {_vm.$set(_vm.form, "observation", $$v)},expression:"form.observation"}})],1),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Cancelar","color":"negative"}}),_c('q-btn',{attrs:{"label":"Confirmar","color":"positive"},on:{"click":_vm.confirm}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }