<template>
  <q-dialog v-if="opened" v-model="opened" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <span class="text-h6"
          >Incluão de transação de
          <span :class="`text-${colorTitle}`">{{ title }}</span> do ativo
          <b>{{ ticker.ticker }}</b></span
        >
      </q-card-section>
      <q-card-section>
        {{ showDate }}
        <q-btn icon="event" round color="primary" size="xs">
          <q-popup-proxy
            @before-show="updateProxy"
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date v-model="proxyDate">
              <div class="row items-center justify-end q-gutter-sm">
                <q-btn label="Cancel" color="primary" flat v-close-popup />
                <q-btn
                  label="OK"
                  color="primary"
                  flat
                  @click="save"
                  v-close-popup
                />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-btn>

        <q-field v-model="form.quantity" label="Quantidade">
          <template v-slot:control="{ id, floatingLabel, value, emitValue }">
            <money
              :id="id"
              class="q-field__input text-right"
              :value="value"
              @input="emitValue"
              v-bind="numberFormat"
              v-show="floatingLabel"
            />
          </template>
        </q-field>
        <q-field v-model="form.price" label="Preço Unitário">
          <template v-slot:control="{ id, floatingLabel, value, emitValue }">
            <money
              :id="id"
              class="q-field__input text-right"
              :value="value"
              @input="emitValue"
              v-bind="moneyFormat"
              v-show="floatingLabel"
            />
          </template>
        </q-field>
        <q-input
          v-model="form.observation"
          type="textarea"
          label="Observações"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="Cancelar" color="negative" v-close-popup />
        <q-btn label="Confirmar" color="positive" @click="confirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { Money } from "v-money";
import { date } from "quasar";

export default {
  components: {
    Money
  },
  data() {
    return {
      proxyDate: "2019/03/01",
      opened: false,
      operation: null,
      ticker: null,
      form: null,
      title: null,
      colorTitle: null,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false
      },
      numberFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 8,
        masked: false
      }
    };
  },
  computed: {
    showDate() {
      const day = this.form.date.substr(6, 2);
      const month = this.form.date.substr(4, 2);
      const year = this.form.date.substr(0, 4);
      return `${day}/${month}/${year}`;
    }
  },
  methods: {
    ...mapActions("Tickers", ["getCurrencyExchangeRateByDate"]),
    updateProxy() {
      const day = this.form.date.substr(6, 2);
      const month = this.form.date.substr(4, 2);
      const year = this.form.date.substr(0, 4);
      this.proxyDate = `${year}/${month}/${day}`;
    },
    save() {
      const day = this.proxyDate.substr(8, 2);
      const month = this.proxyDate.substr(5, 2);
      const year = this.proxyDate.substr(0, 4);
      this.form.date = `${year}${month}${day}`;
    },
    initForm() {
      this.form = {
        quantity: 0,
        date: date.formatDate(Date.now(), "YYYYMMDD"),
        price: 0,
        observation: "",
        operation: this.operation
      };
    },
    open(operation, ticker) {
      this.operation = operation;
      this.ticker = ticker;
      this.initForm();
      this.title = operation === "add" ? "compra" : "venda";
      this.colorTitle = operation === "add" ? "positive" : "negative";
      this.opened = true;
      this.numberFormat.precision = ticker.decimalPlaces;
      this.proxyDate = this.form.date;
    },
    async confirm() {
      if (this.form.quantity > 0 && this.form.price > 0) {
        try {
          this.opened = false;
          if (!this.ticker.transactions) {
            this.ticker.transactions = [];
          }
          if (!this.ticker.itIsCrypto && !this.ticker.tickerIsOnB3) {
            this.form.exchangeRate = await this.getCurrencyExchangeRateByDate(
              this.form.date
            );
          }
          this.ticker.transactions.push(this.form);
          this.$emit("confirm", this.ticker);
        } catch (error) {
          this.$q.notify({
            color: "red",
            textColor: "white",
            message: "Falha ao obter cotação do dolar no dia da compra",
            icon: "announcement",
            position: "top"
          });
        }
      }
    }
  }
};
</script>

<style></style>
